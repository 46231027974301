import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { differenceInHours } from 'date-fns';
import Footer from './Footer';
import Header from './Header';

const RadioPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const scheduledTrackRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [tracks, setTracks] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [newTrack, setNewTrack] = useState('');
  const [newTime, setNewTime] = useState('');
  const [alarmName, setAlarmName] = useState(''); // New state for alarm name
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString('en-GB', { hour12: false }));
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userInteracted, setUserInteracted] = useState(false);
  const [playingTrack, setPlayingTrack] = useState(null);
  const [highlightEndTime, setHighlightEndTime] = useState(null);

  useEffect(() => {
    fetchRadioProfile();
    fetchTracks();
    fetchSchedule();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().toLocaleTimeString('en-GB', { hour12: false });
      setCurrentTime(now);
      const scheduledTrack = schedule.find(track => track.time === now);
      if (scheduledTrack) {
        playScheduledTrack(scheduledTrack.track);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [schedule, profile, currentTime]);

  useEffect(() => {
    if (!profile) return;

    const audioElement = audioRef.current;
    if (!audioElement) return;

    const handleAudioError = () => {
      console.error('Stream error, attempting to reload the stream...');
    };

    const handleOnline = () => {
      console.log('Internet connection restored, refreshing the page...');
      setIsOnline(true);
      window.location.reload(true);
    };

    const handleOffline = () => {
      console.log('Internet connection lost.');
      setIsOnline(false);
    };

    audioElement.addEventListener('error', handleAudioError);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    audioElement.muted = false;
    setIsMuted(false);

    return () => {
      audioElement.removeEventListener('error', handleAudioError);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [profile]);

  useEffect(() => {
    const interval = setInterval(() => {
      preloadNextTrack();
    }, 5000); // Check every 5 seconds to preload the next track
    return () => clearInterval(interval);
  }, [schedule, currentTime]);

  const fetchRadioProfile = async () => {
    try {
      const response = await axios.get(`/radiostreams/${id}`);
      setProfile(response.data);
    } catch (error) {
      console.error('There was an error fetching the radio profile!', error);
    }
  };

  const fetchTracks = () => {
    axios.get(`/radio/${id}/tracks`)
      .then(response => {
        setTracks(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the tracks!', error);
      });
  };

  const fetchSchedule = () => {
    axios.get(`/radio/${id}/schedulepresetcurrentdate`)
      .then(response => {
        setSchedule(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the schedule!', error);
      });
  };

  const handlePlay = () => {
    if (!profile || profile.blocked) return;

    const audioElement = audioRef.current;
    setIsLoading(true);
    audioElement.src = profile.url;
    audioElement.load();
    audioElement.play().then(() => {
      setIsLoading(false);
    }).catch(error => {
      console.error('Error attempting to play the stream:', error);
      setIsLoading(true);
    });
  };

  const handleUserInteraction = () => {
    setUserInteracted(true);
    handlePlay(); // Automatically play when the user interacts
  };

  const handleMute = () => {
    if (!audioRef.current) return;
    audioRef.current.muted = !audioRef.current.muted;
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    if (!audioRef.current) return;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const playScheduledTrack = (track) => {
    if (!profile || profile.alarmBlocked || !audioRef.current || !scheduledTrackRef.current) return;

    setPlayingTrack(track);
    fadeOut(audioRef.current, () => {
      scheduledTrackRef.current.src = `/uploads/tracks/${track}`;
      console.log('Track source set to:', scheduledTrackRef.current.src);
      scheduledTrackRef.current.load();
      scheduledTrackRef.current.oncanplaythrough = () => {
        scheduledTrackRef.current.play().catch(error => {
          console.error('Error playing track:', error);
        });
      };
      scheduledTrackRef.current.onended = () => {
        setPlayingTrack(null);
        fadeIn(audioRef.current);
        audioRef.current.play();
      };

      // Calculate the end time for highlighting
      const trackDuration = scheduledTrackRef.current.duration * 1000; // duration in milliseconds
      const endTime = new Date().getTime() + trackDuration + 20000; // add 20 seconds
      setHighlightEndTime(endTime);
    });
  };

  const fadeOut = (audio, callback) => {
    let volume = 1.0;
    const fadeAudio = setInterval(() => {
      if (volume > 0.1) {
        volume -= 0.1;
        audio.volume = volume;
      } else {
        clearInterval(fadeAudio);
        audio.volume = 0;
        callback();
      }
    }, 200);
  };

  const fadeIn = (audio) => {
    let volume = 0;
    const fadeAudio = setInterval(() => {
      if (volume < 0.9) {
        volume += 0.1;
        audio.volume = volume;
      } else {
        clearInterval(fadeAudio);
        audio.volume = 1.0;
      }
    }, 200);
  };

  const preloadNextTrack = () => {
    const now = new Date();
    const futureTracks = schedule.filter(track => {
      const [hours, minutes, seconds] = track.time.split(':').map(Number);
      const trackTime = new Date();
      trackTime.setHours(hours, minutes, seconds);
      return trackTime > now;
    });

    if (futureTracks.length > 0) {
      const nextTrack = futureTracks[0];
      const nextTrackTime = new Date();
      const [hours, minutes, seconds] = nextTrack.time.split(':').map(Number);
      nextTrackTime.setHours(hours, minutes, seconds);

      // Preload the track 60 seconds before the scheduled time
      if (nextTrackTime - now <= 60000 && scheduledTrackRef.current.src !== `/uploads/tracks/${nextTrack.track}`) {
        scheduledTrackRef.current.src = `/uploads/tracks/${nextTrack.track}`;
        scheduledTrackRef.current.load();
      }
    }
  };

  const handleAddTrack = () => {
    const newSchedule = { track: newTrack, time: newTime, alarmName }; // Include alarm name
    const updatedSchedule = [...schedule, newSchedule];
    setSchedule(updatedSchedule);
    axios.post(`/radio/${id}/schedulecurrentdate`, updatedSchedule)
      .then(response => {
        console.log('Schedule saved to server');
      })
      .catch(error => {
        console.error('Error saving schedule to server', error);
      });
    setNewTrack('');
    setNewTime('');
    setAlarmName(''); // Clear alarm name input
  };

  const getDaysLeft = (expirationDate, createdDate, subscriptionPlan) => {
    const now = new Date();
    const created = new Date(createdDate);
    const expiration = new Date(expirationDate);

    let subscriptionDays = 0;
    switch (subscriptionPlan) {
      case '1 Day':
        subscriptionDays = 1;
        break;
      case '1 Month':
        subscriptionDays = 30;
        break;
      case '3 Months':
        subscriptionDays = 90;
        break;
      case '6 Months':
        subscriptionDays = 180;
        break;
      case '1 Year':
        subscriptionDays = 365;
        break;
      default:
        subscriptionDays = 0;
    }

    const hoursPassed = differenceInHours(now, created);
    const daysPassed = Math.ceil(hoursPassed / 24);
    const daysLeft = subscriptionDays - daysPassed;

    // console.log(`Current Date: ${now}`);
    // console.log(`Created Date: ${created}`);
    // console.log(`Expiration Date: ${expiration}`);
    // console.log(`Hours Passed: ${hoursPassed}`);
    // console.log(`Days Passed (ceil): ${daysPassed}`);
    // console.log(`Subscription Days: ${subscriptionDays}`);
    // console.log(`Days Left: ${daysLeft}`);

    return daysLeft;
  };

  const isNextToPlay = (scheduledTime) => {
    const now = new Date();
    const scheduledDate = new Date();
    const [hours, minutes, seconds] = scheduledTime.split(':').map(Number);
    scheduledDate.setHours(hours, minutes, seconds);
    const differenceInMinutes = (scheduledDate - now) / 60000;
    return differenceInMinutes >= 0 && differenceInMinutes <= 1;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const sortedSchedule = [...schedule].sort((a, b) => {
    const [aHours, aMinutes] = a.time.split(':').map(Number);
    const [bHours, bMinutes] = b.time.split(':').map(Number);
    return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
  });

  if (!profile) {
    return <div>Loading...</div>;
  }

  if (profile.blocked) {
    return (
      <div className="container vh-50 d-flex flex-column align-items-center justify-content-center">
        <style>{`
          .blocked-message {
            color: red;
            font-weight: bold;
            font-size: 24px;
          }
        `}</style>
        <div className="blocked-message">Your Radio is blocked.</div>
        <div>{profile.companyName}</div>
        <img
          src={`/uploads/${profile.logo}`}
          alt="Company Logo"
          style={{ maxWidth: '40%', height: 'auto', marginRight: '15px' }}
        />
      </div>
    );
  }

  return (
    <div>
          
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <h1 style={{ fontSize: '2rem', marginTop: '20px' }}><b>{profile.name} - {profile.companyName}</b></h1>
            <div style={{ textAlign: 'center', fontSize: '1rem', marginTop: '10px' }}>
              <p>
                Subscription Plan: {profile.subscriptionPlan}<br />
                Days left to expire: {getDaysLeft(profile.expirationDate, profile.createdDate, profile.subscriptionPlan)}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', padding: '10px', marginTop: '0' }}>
  {/* Top section: Radio Player */}
  <div style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}>
    <div onClick={handleUserInteraction} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {/* Smaller logo */}
      {profile.logo && (
        <img
          src={`/uploads/${profile.logo}`}
          alt="Company Logo"
          style={{ maxWidth: '25%', height: 'auto', marginRight: '15px' }}
        />
      )}

      {/* Hidden audio player */}
      <audio ref={audioRef} preload="none" style={{ display: 'none' }}></audio>

      {/* Buttons and Controls */}
      <div style={{ maxWidth: '150px' }}>
        <div className="mb-2">
          {/* Live Button */}
          <button
            className="btn btn-danger btn-block"
            onClick={handlePlay}
            style={{ fontSize: '14px', padding: '5px 10px', marginBottom: '5px' }}
          >
            Live
          </button> &nbsp;

          {/* Mute/Unmute Button */}
          <button
            className="btn btn-danger btn-block"
            onClick={handleMute}
            style={{ fontSize: '14px', padding: '5px 10px', marginBottom: '5px' }}
          >
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
        </div>

        {/* Volume Slider */}
        <div className="mb-2">
          <input
            type="range"
            className="form-control-range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            style={{ width: '100%', height: '6px' }}
          />
        </div>

        {/* Status messages */}
        {isLoading && <p style={{ fontSize: '12px' }}>Connecting...</p>}
        {!isOnline && <p style={{ fontSize: '12px' }}>No connection.</p>}
        {errorMessage && <p style={{ fontSize: '12px' }}>{errorMessage}</p>}
      </div>
    </div>
  </div>
</div>


  {/* Bottom section: Scheduled Tracks */}
<div style={{ width: '100%', textAlign: 'center' }}>
  {profile.alarmBlocked ? (
    <div style={{ padding: '10px' }}>
      <h2 style={{ fontSize: '16px' }}>Alarm System is blocked</h2>
      <p style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
        The alarm system for this profile is currently blocked.
      </p>
    </div>
  ) : (
    <div style={{ padding: '10px' }}>
      <h2 style={{ fontSize: '16px', marginBottom: '10px' }}><b>Scheduled Tracks</b></h2>
      <audio ref={scheduledTrackRef} controls preload="none" style={{ width: '100%', marginBottom: '10px' }}></audio>
      
      {/* Scrollable List - Enhanced Design */}
      <ul style={{
          padding: '0',
          listStyle: 'none',
          maxHeight: '220px', // Adjust to make room for 5 items
          overflowY: 'auto',
          fontSize: '14px',
          border: '1px solid #dee2e6',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          scrollBehavior: 'smooth',
          padding: '10px',
          backgroundColor: '#f8f9fa',
        }}>
        {sortedSchedule.slice(0, 5).map((item, index) => {
          const isPlaying = item.track === playingTrack;
          const isHighlighted = highlightEndTime && new Date().getTime() < highlightEndTime;
          return (
            <li
              key={index}
              style={{
                padding: '8px',
                backgroundColor: isPlaying || isHighlighted ? '#ffecb3' : '#fff',
                borderBottom: index !== sortedSchedule.slice(0, 5).length - 1 ? '1px solid #dee2e6' : 'none',
                borderRadius: '5px',
                transition: 'background-color 0.3s ease',
                fontWeight: isNextToPlay(item.time) ? 'bold' : 'normal',
                cursor: 'pointer'
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isPlaying || isHighlighted ? '#ffecb3' : '#fff'}
            >
              <span
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#ff0000',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '8px'
                }}
              ></span>
              {item.time} - {item.alarmName}
            </li>
          );
        })}
      </ul>

      {/* Scrollbar styling */}
      <style>{`
        /* For WebKit browsers (Chrome, Safari) */
        ul::-webkit-scrollbar {
          width: 8px;
        }
        ul::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 4px;
        }
        ul::-webkit-scrollbar-track {
          background-color: #f8f9fa;
        }
      `}</style>
    </div>
  )}
</div>

  </div>          
       
       
  );
}; 
  export default RadioPlayer;
  