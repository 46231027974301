import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate  } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './RadioProfile.css'; // Import the CSS file
import RadioPlayer from './RadioPlayer';
import AlarmPlayer from './AlarmPlayer';
import logo from './logo.JPG'; // Adjust the path if necessary

const RadioProfile = () => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [newTrack, setNewTrack] = useState('');
  const [newTime, setNewTime] = useState('');
  const [alarmName, setAlarmName] = useState('');
  const [presetName, setPresetName] = useState('');
  const [playingTrack, setPlayingTrack] = useState(null);
  const [playingScheduledTrack, setPlayingScheduledTrack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [presetFilter, setPresetFilter] = useState('');
  const [presetData, setPresetData] = useState([]);
  const [loadingPresetData, setLoadingPresetData] = useState(false);
  const audioRefs = useRef([]);
  const scheduledAudioRefs = useRef([]);
  const presetOptions = useRef([]);
  const [currentDateTime, setCurrentDateTime] = useState({ date: new Date(),});
  const navigate = useNavigate();


  useEffect(() => {
    fetchSchedule();
    fetchTracks();
  }, []);

  useEffect(() => {
    fetchPresetData(selectedDate.toISOString().split('T')[0]);
  }, [selectedDate]);

  const fetchSchedule = () => {
    setLoading(true);
    axios.get(`/radio/${id}/schedule`)
      .then(response => {
        setSchedule(response.data);
        setLoading(false);
        
        // Update preset options based on fetched schedule data
        const presets = [...new Set(response.data.map(item => item.preset))];
        presetOptions.current = presets;
      })
      .catch(error => {
        toast.error('Error fetching schedule');
        setLoading(false);
      });
  };

  const [expandedPresets, setExpandedPresets] = useState({});

  // Toggle the expanded state of a preset
  const togglePreset = (preset) => {
    setExpandedPresets((prev) => ({
      ...prev,
      [preset]: !prev[preset],
    }));
  };

  const fetchTracks = () => {
    setLoading(true);
    axios.get(`/radio/${id}/tracks`)
      .then(response => {
        setTracks(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching tracks');
        setLoading(false);
      });
  };

  const fetchPresetData = (date) => {
    setLoadingPresetData(true);
    axios.get(`/radio/${id}/schedulepreset`, { params: { date } })
      .then(response => {
        setPresetData(response.data);
        setLoadingPresetData(false);
      })
      .catch(error => {
        toast.error('Error fetching preset data');
        setLoadingPresetData(false);
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      toast.error('Please select a file to upload');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    axios.post(`/radio/${id}/upload`, formData)
      .then(response => {
        toast.success('File uploaded successfully');
        fetchTracks(); // Refresh tracks after upload
        setLoading(false);
      })
      .catch(error => {
        toast.error('File upload failed');
        setLoading(false);
      });
  };



  const handleDeleteScheduleItem = (alarmName) => {
    setLoading(true);
    axios.delete(`/radio/${id}/schedule/${encodeURIComponent(alarmName)}`)
      .then(response => {
        // Update schedule state after deletion
        const updatedSchedule = schedule.filter(item => item.alarmName !== alarmName);
        setSchedule(updatedSchedule);
        toast.success('Schedule deleted successfully');
        setLoading(false);
      })
      .catch(error => {
        toast.error('Failed to delete schedule');
        setLoading(false);
      });
  };

  const handleDeleteTrack = (track) => {
    const trackName = track.split('-').slice(1).join('-'); // Extract the track name after the first hyphen
    setLoading(true);
    axios.delete(`/radio/${id}/tracks/${trackName}`)
      .then(response => {
        toast.success('Track deleted successfully');
        fetchTracks(); // Refresh tracks after deletion
        setLoading(false);
      })
      .catch(error => {
        toast.error('Failed to delete track');
        setLoading(false);
      });
  };

  const handleAddTrack = () => {
    if (!newTrack || !newTime || !alarmName || !presetName) {
      toast.error('Please select a track, time, enter alarm name, and preset name');
      return;
    }

    setLoading(true);
    const newSchedule = { track: newTrack, time: newTime, alarmName, preset: presetName };
    const updatedSchedule = [...schedule, newSchedule];
    axios.post(`/radio/${id}/schedule`, updatedSchedule)
      .then(response => {
        setSchedule(updatedSchedule);
        toast.success('Track added to schedule');
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error saving schedule');
        setLoading(false);
      });

    setNewTrack('');
    setNewTime('');
    setAlarmName('');
    setPresetName('');
  };

  const handlePlayPause = (index) => {
    const audio = audioRefs.current[index];
    if (audio) {
      if (audio.paused) {
        audio.play();
        setPlayingTrack(index);
      } else {
        audio.pause();
        setPlayingTrack(null);
      }
    }
  };

  const handlePlayPauseScheduled = (index) => {
    const audio = scheduledAudioRefs.current[index];
    if (audio) {
      if (audio.paused) {
        audio.play();
        setPlayingScheduledTrack(index);
      } else {
        audio.pause();
        setPlayingScheduledTrack(null);
      }
    }
  };

  const handleDateChange = (date) => {
    // Adjust the selected date to UTC midnight to avoid timezone issues
    const adjustedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    setSelectedDate(adjustedDate);
  };
  

  const handleSavePresetData = () => {
    if (!presetFilter) {
      toast.error('Please select a preset name');
      return;
    }

    const filteredData = schedule.filter(item => item.preset === presetFilter);
    if (filteredData.length === 0) {
      toast.error('No data found for the selected preset');
      return;
    }

    const dataToSave = filteredData.map(item => ({
      ...item,
      date: selectedDate.toISOString().split('T')[0],
    }));

    axios.post(`/radio/${id}/schedulepreset`, dataToSave)
      .then(response => {
        toast.success('Preset data saved successfully');
      })
      .catch(error => {
        toast.error('Failed to save preset data');
      });
  };

  const handleDeleteByDate = () => {
    const formattedDate = selectedDate.toISOString().split('T')[0];

    axios.delete(`/radio/${id}/schedulepreset/${formattedDate}`)
      .then(response => {
        toast.success('Records deleted successfully');
        fetchPresetData(formattedDate); // Optionally refresh data after deletion
      })
      .catch(error => {
        toast.error('Failed to delete records');
      });
  };
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const tileContent = ({ date }) => {
    const preset = presetData.find(p => new Date(p.date).toDateString() === date.toDateString());
    return preset ? (
      <div className="calendar-tile-content">
        <strong>{preset.preset}</strong>
      </div>
    ) : null;
  };

  // Organize schedule by preset
  const organizedSchedule = schedule.reduce((acc, item) => {
    if (!acc[item.preset]) {
      acc[item.preset] = [];
    }
    acc[item.preset].push(item);
    return acc;
  }, {});

  // Function to update the current date and time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime({ date: new Date() });
    }, 1000);

    // Cleanup the timer when the component is unmounted
    return () => clearInterval(timer);
  }, []);
 

    // Internal CSS styles
    const styles = {
      container: {
        backgroundColor: '#f4f4f4',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      },
      header: {
        backgroundColor: '#343a40',
        color: '#fff',
        padding: '15px',
        textAlign: 'center',
      },
      sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '20px',
      },
      section: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        flex: 1,
        padding: '20px',
        overflowY: 'auto',
      },
      spinnerContainer: {
        textAlign: 'center',
        marginBottom: '20px',
      },
      buttonGroup: {
        marginTop: '10px',
      },
      fileUpload: {
        marginBottom: '20px',
      },
      addTrack: {
        marginTop: '20px',
      },
      formGroup: {
        marginBottom: '20px',
      },
      presetSchedule: {
        marginTop: '20px',
      },
      scheduleItem: {
        backgroundColor: '#e9ecef',
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '10px',
        position: 'relative',
      },
      trackList: {
        listStyle: 'none',
        padding: '0',
      },
      trackItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      },
      trackActions: {
        marginLeft: 'auto',
      },
      footer: {
        backgroundColor: '#343a40',
        color: '#fff',
        padding: '15px',
        textAlign: 'center',
      },
      presetsList: {
        marginBottom: '20px',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: '#e9ecef',
      },
      collapseIcon: {
        cursor: 'pointer',
        marginLeft: '10px',
      },
      presetHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      alarmCount: {
        fontWeight: 'normal',
        marginLeft: '10px',
      },
      deleteButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
      },
      header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#333',
        color: '#fff',
        position: 'relative', // For better control over time placement
      },
      time: {
        position: 'absolute',
        left: '10px',
        fontSize: '18px',
      },
      title: {
        margin: 2,
        fontSize: '30px',
      },
    };

    const formattedDate = currentDateTime.date.toLocaleDateString();
    const formattedTime = currentDateTime.date.toLocaleTimeString();
  
    return (
    <div>
        {/* Header */}
       <header style={styles.header}>
  {/* Current Date and Time on the Left */}
  <div style={styles.time}>
    {formattedDate} {formattedTime}
  </div>

  {/* Title in the Center */}
  <h1 style={styles.title}>Radio & Alarm Control Dashboard</h1>

  {/* Buttons on the Right */}
  <div style={{ display: 'flex', gap: '10px', position: 'absolute', right: '10px' }}>
    <Link to={`/localplayer/${id}`}>
      <button className="btn btn-primary">Go to Local Player</button>
    </Link>
    <button className="btn btn-danger" onClick={handleLogout}>
      Logout
    </button>
  </div>
</header>
<div style={styles.container}>


      
    
  
        {/* Main Content */}
        <div style={styles.sectionContainer}>
          {/* Left Section */}
          <div style={styles.section}>
            {/* Loading Spinner */}
            {loading && (
              <div style={styles.spinnerContainer}>
                <Oval
                  height={50}
                  width={50}
                  color="#007bff"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#007bff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
  
            {/* Schedule Calendar */}
            <div style={{  marginTop: '20px' }}>
            <section>
          
              <h2><b>Alarm Schedule Calendar</b></h2>
              <Calendar onChange={handleDateChange} value={selectedDate} tileContent={tileContent} />

              <br></br> <br></br> <br></br>
             
              {/* Save Preset Data */}
              <div style={styles.formGroup}>
                <h3><b>Save Preset Data</b></h3>
                <select
                  className="form-control"
                  value={presetFilter}
                  onChange={(e) => setPresetFilter(e.target.value)}
                >
                  <option value="">Select Preset</option>
                  {presetOptions.current.map((preset, index) => (
                    <option key={index} value={preset}>
                      {preset}
                    </option>
                  ))}
                </select>
                <div style={styles.buttonGroup}>
                  <button className="btn btn-primary" onClick={handleSavePresetData} disabled={!presetFilter}>
                    Save Preset Data
                  </button>&nbsp; 
                  <p></p>
                  <button className="btn btn-danger" onClick={handleDeleteByDate}>
                    Delete Records for Selected Date
                  </button>
                </div>
              </div>
             
            </section>
            </div>
            </div>
          
         
  
          {/* Center Section */}
          <div style={styles.section}>
            {/* File Upload and Track Addition */}
            <section style={styles.addTrack}>
              <h3><b>Upload Alarm Tracks</b></h3>
              <div style={styles.fileUpload}>
                <input type="file" onChange={handleFileChange} />
                <button className="btn btn-success" onClick={handleUpload}>
                  Upload
                </button>
              </div>
              <h3><b>Create Alarms</b></h3>
  
              <div style={styles.formGroup}>
              <input
                 type="time"
                 className="form-control"
                 value={newTime}
                 step="1" // Allows hours, minutes, and seconds
                 onChange={(e) => setNewTime(e.target.value)}
               /> <br></br>
                <select
                  className="form-control"
                  value={newTrack}
                  onChange={(e) => setNewTrack(e.target.value)}
                > <br></br>
                  <option value="">Select Track</option>
                  {tracks.map((track, index) => {
                    const trackName = track.split('-').slice(1).join('-');
                    return (
                      <option key={index} value={track}>
                        {trackName}
                      </option>
                    );
                  })}
                </select> <br></br>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter alarm name"
                  value={alarmName}
                  onChange={(e) => setAlarmName(e.target.value)}
                /> <br></br>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter preset name"
                  value={presetName}
                  onChange={(e) => setPresetName(e.target.value)}
                />
              </div> <br></br>
              <button
                className="btn btn-info"
                onClick={handleAddTrack}
                disabled={!newTrack || !newTime || !alarmName || !presetName}
              >
                Add Alarms
              </button>
            </section>
  
            {/* Available Tracks */}
            <section style={styles.addTrack}>
              <h3><b>Available Alarms</b></h3>
              {tracks.length === 0 ? (
                <p>No alamrs available</p>
              ) : (
                <ul style={styles.trackList}>
                  {tracks.map((track, index) => {
                    const trackName = track.split('-').slice(1).join('-');
                    return (
                      <li key={index} style={styles.trackItem}>
                        <audio ref={(el) => (audioRefs.current[index] = el)} src={`/uploads/tracks/${track}`} />
                        <strong>{trackName}</strong>
                        <div style={styles.trackActions}>
                          <button className="btn btn-primary" onClick={() => handlePlayPause(index)}>
                            {playingTrack === index ? 'Pause' : 'Play'}
                          </button> &nbsp;
                          <button className="btn btn-danger" onClick={() => handleDeleteTrack(track)}>
                            Delete
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </section>
          </div>
  
          {/* Right Section */}
          <div style={styles.section}>
           
            <section>
                
              <RadioPlayer/>
            </section>
          </div>


          {/* <div style={styles.section}>
        
            <section>
              <h2>Available Preset Names</h2>
              <ul style={styles.presetsList}>
                {presetOptions.current.map((preset, index) => (
                  <li key={index}>
                    <strong>{preset}</strong>
                  </li>
                ))}
              </ul>
            </section>
          </div>  */}
        </div>
  
       {/* New Section: Detailed Schedule by Preset */}
<div style={styles.section}>
  <h2><b>Alarms Scheduled by Preset</b></h2>
  {Object.keys(organizedSchedule).length === 0 ? (
    <p>No Alarms scheduled</p>
  ) : (
    Object.keys(organizedSchedule).map((preset, index) => (
      <div key={index} style={styles.presetSchedule}>
        {/* Display preset name with alarm count */}
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1, textAlign: 'left' }}>{preset}</span>
          <span style={{ flex: 1, textAlign: 'center' }}>
            {organizedSchedule[preset].length} Alarms have been Added
          </span>
          <span
            style={{ cursor: 'pointer', flex: 0 }}
            onClick={() => togglePreset(preset)}
          >
            {expandedPresets[preset] ? '▼' : '▶'}
          </span>
        </h3>
        {/* Conditionally render the schedule items based on the expanded state */}
{organizedSchedule[preset].map((item, idx) => {
  // Add a check to ensure item.trackName is defined
  const cleanedTrackName = item.track ? item.track.replace(/^\d+-/, '') : 'Unknown Track';
  return (
    <div key={idx} style={styles.scheduleItem}>
      <strong>Time:</strong> {item.time} &nbsp;  <strong>Track Name:</strong>  {cleanedTrackName} &nbsp;  <strong>Alarm Name:</strong>{item.alarmName} 
      <button
        className="btn btn-danger btn-sm"
        style={styles.deleteButton}
        onClick={() => handleDeleteScheduleItem(item.alarmName)}
      >
        Delete
      </button>
    </div>
  );
})}


      </div>
    ))
  )}
</div>

       </div>
        {/* Footer */}
        {/* <footer style={styles.footer}>
          <p>&copy; 2024 Radio Control Dashboard. All rights reserved.</p>   <img src={logo} alt="Planet Entertainment Logo" />
        </footer> */}

<footer style={{
    backgroundColor: '#333', 
    color: '#fff', 
    textAlign: 'center', 
    padding: '20px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  }}>
  <p style={{ margin: '0' }}>&copy; 2024 Online Radio Alarm System . All rights reserved.</p>
  
  {/* Image Styling */}
  <img 
    src={logo} 
    alt="Planet Entertainment Logo" 
    style={{ 
      marginLeft: '10px', 
      maxWidth: '50px', 
      height: 'auto' 
    }} 
  />
</footer>

      </div>
    );
  };
  
  export default RadioProfile;
  