// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Dashboard from './components/Dashboard';
// import AdminLogin from './components/AdminLogin';
// import RadioPlayer from './components/RadioPlayer';
// import LocalPlayer from './components/LocalPlayer'; // Add this import
// import RadioProfile from './components/RadioProfile';
// import AddProfile from './components/AddProfile';
// import EditProfile from './components/EditProfile';
// import Login from './components/Login';
// import History from './components/History';

// const PrivateRoute = ({ element: Element, ...rest }) => {
//   const isAuthenticated = !!localStorage.getItem('token') || !!localStorage.getItem('adminToken');
//   return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />;
// };

// const AdminPrivateRoute = ({ element: Element, ...rest }) => {
//   const isAuthenticated = !!localStorage.getItem('adminToken');
//   return isAuthenticated ? <Element {...rest} /> : <Navigate to="/admin/login" />;
// };

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to="/admin/login" />} />
//         <Route path="/admin/login" element={<AdminLogin />} />
//         <Route path="/dashboard" element={<AdminPrivateRoute element={Dashboard} />} />
//         <Route path='/history' element={<AdminPrivateRoute element={History} />} />
//         <Route path="/add" element={<PrivateRoute element={AddProfile} />} />
//         <Route path="/radio/:id/edit" element={<PrivateRoute element={EditProfile} />} />
//         <Route path="/radio/:id/profile" element={<PrivateRoute element={RadioProfile} />} />
//         <Route path="/radio/:id" element={<PrivateRoute element={RadioPlayer} />} />
//         <Route path="/localplayer/:id" element={<PrivateRoute element={LocalPlayer} />} /> {/* Add this route */}
//         <Route path="/login" element={<Login />} />
       
//       </Routes>
//     </Router>
//   );
// };

// export default App;
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Dashboard from './components/Dashboard';
// import AdminLogin from './components/AdminLogin';
// import RadioPlayer from './components/RadioPlayer';
// import LocalPlayer from './components/LocalPlayer';
// import RadioProfile from './components/RadioProfile';
// import AddProfile from './components/AddProfile';
// import EditProfile from './components/EditProfile';
// import Login from './components/Login';
// import History from './components/History';

// const PrivateRoute = ({ children }) => {
//   const isAuthenticated = !!localStorage.getItem('token') || !!localStorage.getItem('adminToken');
//   return isAuthenticated ? children : <Navigate to="/login" />;
// };

// const AdminPrivateRoute = ({ children }) => {
//   const isAuthenticated = !!localStorage.getItem('adminToken');
//   return isAuthenticated ? children : <Navigate to="/admin/login" />;
// };

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to="/admin/login" />} />
//         <Route path="/admin/login" element={<AdminLogin />} />
//         <Route path="/dashboard" element={<AdminPrivateRoute><Dashboard /></AdminPrivateRoute>} />
//         <Route path='/history' element={<AdminPrivateRoute><History /></AdminPrivateRoute>} />
//         <Route path="/add" element={<PrivateRoute><AddProfile /></PrivateRoute>} />
//         <Route path="/radio/:id/edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
//         <Route path="/radio/:id/profile" element={<PrivateRoute><RadioProfile /></PrivateRoute>} />
//         <Route path="/radio/:id" element={<PrivateRoute><RadioPlayer /></PrivateRoute>} />
//         <Route path="/localplayer/:id" element={<PrivateRoute><LocalPlayer /></PrivateRoute>} />
//         <Route path="/login" element={<Login />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AdminLogin from './components/AdminLogin';
import RadioPlayer from './components/RadioPlayer';
import LocalPlayer from './components/LocalPlayer';
import RadioProfile from './components/RadioProfile';
import AddProfile from './components/AddProfile';
import EditProfile from './components/EditProfile';
import Login from './components/Login';
import History from './components/History';
import PrivateRoute from './components/PrivateRoute';  // Make sure this import is correct
import AlarmPlayer from './components/AlarmPlayer';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='/history' element={<PrivateRoute><History /></PrivateRoute>} />
        <Route path="/add" element={<PrivateRoute><AddProfile /></PrivateRoute>} />
        <Route path="/radio/:id/edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
        <Route path="/radio/:id/profile" element={<PrivateRoute><RadioProfile /></PrivateRoute>} />
        <Route path="/radio/:id" element={<PrivateRoute><RadioPlayer /></PrivateRoute>} />
        <Route path="/alarm/:id" element={<PrivateRoute><AlarmPlayer /></PrivateRoute>} />
        <Route path="/localplayer/:id" element={<PrivateRoute><LocalPlayer /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
