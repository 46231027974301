import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const user = localStorage.getItem('user');

  try {
    // Parse the user data from localStorage
    const parsedUser = JSON.parse(user);

    // Check if both username and password are available in localStorage
    if (
      parsedUser &&
      parsedUser.username &&
      parsedUser.password
    ) {
      // If valid, allow access to the route
      return children;
    }
  } catch (e) {
    console.error("Error parsing user data:", e);
  }

  // If no valid user data is found or credentials are missing, redirect to the login page
  return <Navigate to="/login" />;
};

export default PrivateRoute;
