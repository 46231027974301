import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', { username, password });
      
      if (response.data.user) {
        // Save id, username, and password to localStorage as the user item
        const user = {
          id: response.data.user.id,
          username: response.data.user.username,
          password: password // Store the password (although usually not recommended for security reasons)
        };
        localStorage.setItem('user', JSON.stringify(user));
        
        // Redirect to the user's profile page
        navigate(`/radio/${response.data.user.id}/profile`);
      } else {
        setMessage('Invalid username or password');
      }
    } catch (error) {
      setMessage('Error logging in. Please try again.');
    }
  };

  return (
    <div>
      <Header />
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="col-md-4">
          <div className="card p-4 shadow-sm">
            <h2 className="text-center text-primary mb-4">Login</h2>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-3">Login</button>
            </form>
            {message && <p className="mt-3 text-center text-danger">{message}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
